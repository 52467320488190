import { gql } from "@apollo/client";

export const GET_ORGANISATIONS = gql`
  query MyQuery {
    msgbox_Organisation(order_by: { Name: asc }) {
      OrganisationId
      Name
    }
  }
`;

export const GET_API_ACCOUNTS = gql`
  query MyQuery($orgId: Int) {
    msgbox_APIAccount(
      where: { OrganisationId: { _eq: $orgId } }
      order_by: { Name: asc }
    ) {
      APIAccountId
      Name
    }
  }
`;

export const GET_TEMPLATES = gql`
  query MyQuery($apiAccountId: Int!) {
    msgbox_Template(where: {APIAccount: {_eq: $apiAccountId}, Status: {_eq: Approved}, IsDeleted: {_eq: false}}, order_by: {Name: asc}) {
      Name
      TemplateId
    }
  }`;

  export const GET_PARSERTYPES = gql`
  query MyQuery {
    msgbox_EmailParserType {
      Name
    }
  }`;

