import { gql } from '@apollo/client';

export const UPDATE_ORG = gql`
mutation MyMutation($Name: String!, $billingRef: String, $houseAccount: Boolean! $OrganisationId: Int!) {
    update_msgbox_Organisation(where: {OrganisationId: {_eq: $OrganisationId}}, _set: {Name: $Name, BillingRef: $billingRef, HouseAccount: $houseAccount}) {
        affected_rows
    }
}
`;


export const GET_ORG_DETAILS = gql`
query MyQuery ($orgId: Int!) {
  msgbox_Organisation(where: {OrganisationId: {_eq: $orgId}}) {
    BillingRef
    HouseAccount
  }
}
`;


