import { gql } from "@apollo/client";

export const MESSAGES_SENT_DATA = gql`
query getMessageCount($start: date, $end: date) {
  reporting_MessageStatusByReseller(where: {Date: {_gte: $start, _lte: $end}}, order_by: {Date: asc}) {
    SentCount
    Date
  }
}
`;  

