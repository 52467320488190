import { StringNullableChain } from "lodash";
import React, { useState, useContext } from "react";
import { FormGroup, Input } from "reactstrap";
import { ThemeContext } from "src/helpers/themeContext";

interface IProps {
  template_id: number;
  name: string;
  text: string;
  footer: String;
  buttons: String;
  example: string;
  template_type: string;
  template_category: string;
  onClick: Function;
  showExample: boolean;
  attachmentType: string;
  attachmentFileType: string;
  attachmentExample: string;
}

const TemplateEntry = (props: IProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const wasClicked = () => {
    setIsActive(!isActive);
    props.onClick(
      props.template_id,
      props.name,
      props.text,
      props.footer,
      props.buttons,
      props.example,
      props.template_type,
      props.template_category,
      props.attachmentType,
      props.attachmentFileType,
      props.attachmentExample
    );
  };
  const Theme = useContext(ThemeContext)
  return (
    <tr
      onClick={wasClicked}
          >
      <th scope="row">
        <FormGroup check>
          <Input checked={isActive} type="checkbox" readOnly />
        </FormGroup>
      </th>
      <th scope="row">{props.name}</th>
      <td>{props.showExample ? props.example : props.text}</td>
    </tr>
  );
};

export default TemplateEntry;
