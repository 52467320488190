import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation, useQuery } from "@apollo/client";
import SweetAlert from "react-bootstrap-sweetalert";
import { GET_ORG_DETAILS, UPDATE_ORG } from "./editOrg.query";
import { ThemeContext } from "src/helpers/themeContext";
import { GET_ORGANISATIONS } from "../AddPrebuiltTemplate/query";




interface IProps {
   
  editOrgNameModel: boolean;
  setEditOrgNameModel: Dispatch<SetStateAction<boolean>>;
  PassedUser : any;  
  onRefresh: any; 
  }

const EditOrg = (props: IProps)=>{

  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const Theme = useContext(ThemeContext)
  const [houseAccountOptions, setHouseAccountOptions] = useState([{value: 0, label: "False"}, {value: 1, label: "True"}])
  const [houseAccount, setHouseAccount] = useState<number>()
  const [billingRef, setBillingRef] = useState("")

  const [OrgName, setOrgName] = useState('');
  const [Id, setId] = useState(-1);


  const { data, refetch } = useQuery(GET_ORG_DETAILS, {
    variables:{
        orgId: Id
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (props.PassedUser) {
    setOrgName(props.PassedUser.Name);
    setId(props.PassedUser.Id);
    }
  }, [props]);

  useEffect(()=>{
    if(data){
        setBillingRef(data?.msgbox_Organisation[0]?.BillingRef || "")
        if(data?.msgbox_Organisation[0]?.HouseAccount ===true){
            setHouseAccount(houseAccountOptions[1].value)
        }
        else{
            setHouseAccount(houseAccountOptions[0].value)
        }        
    }
  },[data]);

  useEffect(()=>{
    // console.log("house account value is now:", houseAccount)
  },[houseAccount]);



  const handleShow = () => {
    props.setEditOrgNameModel(false);
  };
  const toggle = () => {
    props.setEditOrgNameModel(!props.editOrgNameModel);
  };

  const handleModalOpen = () => { };
  
  const [updateOrg] = useMutation(UPDATE_ORG);

  const handleSubmit =  (values: any) => {

    // console.log("attempting to submit values: ")
    // console.log("org ID: ", Id);
    // console.log("Name:", values.Rename);
    // console.log("bill ref:", values["billingref"]===""? null : values["billingref"]);
    // console.log("house account: ", Number(houseAccount)===0 ? false : true);

       updateOrg({
          variables :{
            OrganisationId:Id,
            Name:values.Rename,
            billingRef: values["billingref"]===""? null : values["billingref"],
            houseAccount: Number(houseAccount)===0 ? false : true     
          }
        })
        .then((response)=>{
          console.log("Data Updated successfully");
          setdynamic_description("Organization edited successfully");
          setsuccess_dlg(true);
          toggle();        
        })
        .catch((error)=>{
          console.log(error.message);
          setdynamic_description("Failed to Update the data");
          setfailure_dlg(true);
          toggle();
        })     
     }

    return(
    <React.Fragment>
        {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefresh();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}

      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      
    <Modal
    isOpen={props.editOrgNameModel}
    toggle={toggle}
    size="lg"
    onOpened={() => handleModalOpen()}
    >

    <ModalHeader toggle={toggle} tag="h4">
    Edit Organization
    </ModalHeader>

        <ModalBody className="modelStyle">
            <AvForm  
                onValidSubmit={(e: any, values: any) => {
                handleSubmit(values);
              }}> 
                <Row form className="d-flex flex-row mb-3">
                    <Col>
                    
                    <div className="mb-3">
                    <AvField
                        className="avInput"
                        name="Rename"
                        label="Organization Name"
                        placeholder="Enter Organization Name"
                        errorMessage="Invalid Name"
                        validate={{
                        required: { value: true },
                        }}
                        value={OrgName}
                        onChange={(e: any)=>{
                            setOrgName(e.target.value)
                        }}
                    />
                    </div>


                    <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="billingref"
                    label="Billing Ref"
                    type="text"
                    placeholder="Enter billing ref"
                    errorMessage=""
                    value={billingRef}
                    onChange={(e: any)=>{
                        setBillingRef(e.target.value)
                    }}
                  />
                </div>


                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="houseaccount"
                    label="House Account"
                    type="select" 
                    value={houseAccount}                   
                    errorMessage="Select house account value"
                    onChange={(e: any)=>{
                      setHouseAccount(e.target.value)
                    }}
                  >
                   {houseAccountOptions.map((option: any, index: any)=>{
                    return <option key={index} value={option.value}>{option.label}</option>
                   })} 
                  </AvField>
                </div>
                    </Col>
                </Row>
                <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
            </AvForm>
        </ModalBody>
        </Modal>

    </React.Fragment>
    );
};

export default EditOrg;