import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USERS } from "./inboxes.query";
import AddInbox from "../../components/AddInbox/AddInbox";
import EditInboxName from "src/components/EditInboxName/EditInboxName";
import AddPrebuiltTemplate from "src/components/AddPrebuiltTemplate/AddPrebuiltTemplate";
import { ThemeContext } from "src/helpers/themeContext";
import SweetAlert from "react-bootstrap-sweetalert";
import { DELETE_INBOX } from "./deleteInboxQuery";
import { DELETE_API } from "./deleteAPIQuery";
import { randomBytes } from "crypto";
import { DELETE_USER_INBOX } from "./deleteUserInbox";
import DeleteModal from "src/components/deleteModal/deleteModal";
import EditInbox from "src/components/EditInboxName/EditInbox";

const Inboxes = () => {
  const [tableData, setTableData] = useState([]);
  const [modal, setModal] = useState(false);
  const [templatesModal, setTemplatesModal] = useState(false);
  const [selectedInbox, setSelectedInbox] = useState<number>(-1);
  const [selectedApiAccountId, setSelectedApiAccountId] = useState<number>(-1);
  const [selectedOrgId, setSelectedOrgId] = useState<number>(-1);
  const [selectedOrgName, setSelectedOrgName] = useState<string>();
  const [editInboxModel, setEditInboxModel] = useState(false);
  const [PassedUser, setPassedUser] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false)
  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const Theme = useContext(ThemeContext);
  const [deleteInbox] = useMutation(DELETE_INBOX);
  const [deleteUserInbox] = useMutation(DELETE_USER_INBOX);

  const [deleteAPI] = useMutation(DELETE_API);

  const columns = [
    {
      dataField: "Id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "APIAccountId",
      text: "ApiAccountId",
      sort: true,
      hidden: false,
    },
    {
      dataField: "OrgId",
      text: "OrgId",
      sort: true,
      hidden: false,
    },
    {
      dataField: "InboxName",
      text: "Inbox Name",
      sort: true,
    },
    {
      dataField: "PhoneNumber",
      text: "Phone Number",
      sort: true,
    },
    {
      dataField: "OrgName",
      text: "Org Name",
      sort: true,
    },
    {
      dataField: "CreatedOn",
      text: "Created",
      sort: true,
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, user: any) => (
        <React.Fragment>
          <UncontrolledDropdown>
            <DropdownToggle tag="button" className="btn btn-light btn-sm">
              <i className="uil uil-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem onClick={() => handleEdit(user) }>
                <i className="mdi mdi-pencil font-size-16 text-success me-1"></i>
                Edit Inbox
              </DropdownItem>
              <DropdownItem onClick={() => setDeleteModal(user)}>
                  <i className="mdi mdi-delete font-size-16 me-1"></i>
                    Delete Inbox
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </React.Fragment>
      ),
    },
  ];
  const defaultSorted: any = [
    {
      dataField: "id",
      order: "asc",
    },
  ];
  const selectRow: any = {
    mode: "checkbox",
  };
  const pageOptions = {
    sizePerPage: 10,
    totalSize: tableData.length, // replace later with size(customers),
    custom: true,
  };
  const { SearchBar } = Search;

  const { data, refetch } = useQuery(GET_USERS, { fetchPolicy: "no-cache" });

  useEffect(() => {
    
    if (data && data.msgbox_APIAccount) {
      var tmpData = data.msgbox_APIAccount.filter((item: any) => item.Organisation).map(function (item: {
        APIAccountId: any;
        OrgId: any;
        InboxId: any;
        CreatedOn: string | number | Date;
        Organisation: { Name: any; OrganisationId: any };
        PhoneNumber: any;
        Inboxes: { Name: any; InboxId: number }[];
      }) {
        return {
          Id: item.Inboxes[0] ? item.Inboxes[0].InboxId : "No inbox assigned",
          APIAccountId: item.APIAccountId,
          OrgId: item.Organisation.OrganisationId,
          CreatedOn: new Date(item.CreatedOn).toLocaleDateString(),
          OrgName: item.Organisation.Name,
          PhoneNumber: item.PhoneNumber,
          InboxName: item.Inboxes[0]
            ? item.Inboxes[0].Name
            : "No inbox assigned",
        };
      });
      setTableData(tmpData);
    }
  }, [data]);

  
  // handle Inbox Rename
  const handleEdit = (user:any) => {
    setSelectedOrgName(user.OrgName)
    setSelectedInbox(user.Id)
    setPassedUser(user);
    toggleRename();
  }
  const toggleRename = () => {
    setEditInboxModel(!editInboxModel);
  };


  //handle AddInbox
  const toggleAddInboxModal = () => {
    setModal(!modal);
  };

  const handleUserClicks = () => {
    toggleAddInboxModal();
  };

  const handleAddTemplatesClicked = (data: any) => {
    console.log("data:", data);
    setSelectedInbox(data.Id);
    setSelectedOrgId(data.OrgId);
    setSelectedOrgName(data.OrgName);
    setSelectedApiAccountId(data.APIAccountId);
    setTemplatesModal(!templatesModal);
  };

  const handleDelete = async (user: any) => {    
    setDeleteModal(false)
    await deleteInbox({
      variables: {
        InboxId: user.Id,
        userId: localStorage.getItem("user_id"),
        time: new Date(Date.now()).toISOString()
      },
    })

    const phoneNumber = randomBytes(20).toString();

    await deleteAPI({
      variables: {
        apiId: user.APIAccountId,
        userId: localStorage.getItem("user_id"),
        time: new Date(Date.now()).toISOString(),
        phoneNumber: phoneNumber
      },
    })

    await deleteUserInbox({
      variables: {
        InboxId: user.Id,
        userId: localStorage.getItem("user_id"),
        time: new Date(Date.now()).toISOString()
      },
    })

    setsuccess_dlg(true)
    setdynamic_description("Item will not be available to the user now, it will be permanently deleted in 60 days")
    refetch();
  };
  
  const refetchData = () => {
    window.location.reload()
    refetch();
  };

  return (
    <React.Fragment>
        {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <DeleteModal isOpen={deleteModal !== false} cancel={()=> setDeleteModal(false)} submit={() => handleDelete(deleteModal)}/>
      <Card>
        <CardHeader>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <h4 className="card-title">All Inboxes</h4>
              </div>
            </Col>
            <Col md={6}>
              <div className="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                <div>
                  <Link to="#" className="btn btn-light" onClick={refetchData}>
                    <i className="uil uil-refresh me-1"></i> Refresh
                  </Link>
                </div>
                <div>
                  <Link
                    to="#"
                    className="btn btn-light"
                    onClick={handleUserClicks}
                  >
                    <i className="uil uil-plus me-1"></i> Add New
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            // columns={columns}
            // data={productData}
          >
            {({ paginationProps, paginationTableProps }) => (
              <ToolkitProvider
                keyField="Id"
                columns={columns}
                data={tableData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="4">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive">
                          <BootstrapTable
                            // responsive
                            bordered={false}
                            striped={false}
                            defaultSorted={defaultSorted}
                            selectRow={selectRow}
                            classes={"table align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />
                          <EditInbox
                             modal={editInboxModel}
                             setModal={setEditInboxModel}
                             onRefetch={refetchData}
                             inboxId={selectedInbox}
                             orgName={selectedOrgName}
                          />
                          <AddInbox 
                            modal={modal} 
                            setModal={setModal}
                            onRefetch={refetchData} 
                          />
                          <AddPrebuiltTemplate
                            onRefetch={refetchData}
                            modal={templatesModal}
                            setModal={setTemplatesModal}
                            inboxId={selectedInbox}
                            apiAccountId={selectedApiAccountId}
                            orgId={selectedOrgId}
                            orgName={selectedOrgName}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-30">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="d-inline">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Inboxes;
