import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import Select, { OnChangeValue } from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { GET_ORGANISATIONS, GET_API_ACCOUNTS } from "../AddPrebuiltTemplate/query";
import client from "../../apollo";
import { toNumber } from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  CreateApiAccount,
  CreateInbox,
  AddUserToInbox,
  ICreateApiProps,
  ICreateInboxProps,
  IAddUserToInboxProps,
  ICreateFolderProps,
  CreateFolder,
  ICreateAutomationReponse,
  CreateAutomation,
  GET_INBOX_PLANS,
} from "src/common/inboxUtility";
import { GET_API_PROVIDER } from "../AddOrganisation/query";
import { GET_USERS } from "./query";
import { useQuery } from "@apollo/client";
import { ThemeContext } from "src/helpers/themeContext";
interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  onRefetch: any;
  orgId?: number
}

const AddInbox = (props: IProps) => {
  const handleShow = () => {
    props.setModal(false);
  };
  const toggle = () => {
    props.setModal(!props.modal);
  };
  const Theme = useContext(ThemeContext)
  const { data: apidata } = useQuery(GET_API_PROVIDER, {fetchPolicy: "no-cache"});
  const {data: planData} = useQuery(GET_INBOX_PLANS)

  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);

  const [organisations, setOrganisations] = useState([]);
  const [users, setUsers] = useState<{ value: any; label: string }[]>([]);

  const [selectedOrg, setSelectedOrg] = useState<number>(-1);
  const [plans, setPlans] = useState<any>([])
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

  useEffect(() => {
    if (organisations.length === 0) {
      loadOrgs();
    }
  });

  useEffect(()=>{
    if(planData){
      let newArray = [];
      for (let index = 0; index < planData.msgbox_Plan.length; index++) {
        const element = planData.msgbox_Plan[index];
        newArray.push({
          value: element.PlanId,
          label: element.Name,
        });
      }
      setPlans(newArray)
    }
  },[planData])

  const loadOrgs = async () => {
    const orgs = await client.query({
      query: GET_ORGANISATIONS,
    });
    const orgData = orgs?.data?.msgbox_Organisation || [];
    const orgId = orgData[0]["OrganisationId"];

    setOrganisations(orgData);
    setSelectedOrg(orgId);
    loadUsers(orgId);
  };

  const loadUsers = async (orgId: number) => {
    const users = await client.query({
      query: GET_USERS,
      variables: {
        orgId: orgId,
      },
    });
    const userData = users?.data?.msgbox_User || [];

    let options: { value: any; label: string }[] = [];
    userData.forEach((element: any) => {
      const name = element.FirstName + " " + element.LastName;
      const data = { value: element.UserId, label: name };
      options.push(data);
    });

    setUsers(options);
  };

  const handleOrgSelect = (target: number) => {
    setSelectedOrg(target);
    loadUsers(target);
  };

  const handleUserSelect = (val: any) => {
    let selUsers: number[] = [];
    val.forEach((element: any) => {
      selUsers.push(element.value);
    });
    setSelectedUsers(selUsers);
  };

  const handleModalOpen = () => {
    if (props.orgId) {
      handleOrgSelect(props.orgId);
    }
  };


  const handleValidInboxSubmit = async (values: any) => {
    // Create API Account
    var newApiAccount: ICreateApiProps | null = {
      apiAccountId: 0,
      providerId: apidata.msgbox_APIProvider[0].APIProviderId,
      orgId: selectedOrg,
      name: values["inboxname"],
      apiKey: values["apikey"],
      appName: values["appname"],
      partnerId: values["partnerid"],
      partnerToken: values["partnertoken"],
      phoneNumber: values["phonenumber"],
    };
    newApiAccount = await CreateApiAccount(newApiAccount).catch(err => {
      setdynamic_description("Failed to create API Account");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (newApiAccount == null) {
      return false;
    }

    // Create Inbox
    const mathcingPlan = plans.filter((plan: any)=>{
      return (plan.label.trim() === values["inboxplan"].trim())
    })
    const planId = mathcingPlan[0].value
    var newInbox: ICreateInboxProps | null = {
      inboxId: 0,
      orgId: selectedOrg,
      apiAccountId: newApiAccount.apiAccountId,
      name: values["inboxname"],
      planId: planId,
    };
    newInbox = await CreateInbox(newInbox).catch(err => {
      setdynamic_description("Failed to create Inbox");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (newInbox == null) {
      return false;
    }

    var inboxFolder: ICreateFolderProps | null = {
      folderId: 0,
      inboxId: newInbox.inboxId,
      name: "Inbox",
      order: 1,
      isInbox: true,
      isArchive: false,
      isSpam: false,
    };
    inboxFolder = await CreateFolder(inboxFolder).catch(err => {
      setdynamic_description("Failed to create Inbox Folder");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (inboxFolder == null) {
      return false;
    }

    var archiveFolder: ICreateFolderProps | null = {
      folderId: 0,
      inboxId: newInbox.inboxId,
      name: "Archive",
      order: 2,
      isInbox: false,
      isArchive: true,
      isSpam: false,
    };
    archiveFolder = await CreateFolder(archiveFolder).catch(err => {
      setdynamic_description("Failed to create Archive Folder");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (archiveFolder == null) {
      return false;
    }

    var spamFolder: ICreateFolderProps | null = {
      folderId: 0,
      inboxId: newInbox.inboxId,
      name: "Spam",
      order: 3,
      isInbox: false,
      isArchive: false,
      isSpam: true,
    };
    spamFolder = await CreateFolder(spamFolder).catch(err => {
      setdynamic_description("Failed to create Spam Folder");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (spamFolder == null) {
      return false;
    }

    var defaultResponse: ICreateAutomationReponse = {
      orgId: selectedOrg,
      apiAccountId: newApiAccount.apiAccountId,
      keyword: "default response",
      reply:
        "Thank you for messaging " +
        values["inboxname"] +
        ". How can we help you today?",
      isDeletable: false,
    };
    const defaultReponse = await CreateAutomation(defaultResponse).catch(
      err => {
        setdynamic_description("Failed to create default automation rule");
        setfailure_dlg(true);
        toggle();
        return null;
      }
    );
    if (defaultReponse == null) {
      return false;
    }

    var defaultAwayResponse: ICreateAutomationReponse = {
      orgId: selectedOrg,
      apiAccountId: newApiAccount.apiAccountId,
      keyword: "default away response",
      reply:
        "Thank you for messaging " +
        values["inboxname"] +
        ". We're currently closed, so we'll be in touch as soon as we can.",
      isDeletable: false,
    };
    const defaultAwayReponse = await CreateAutomation(
      defaultAwayResponse
    ).catch(err => {
      setdynamic_description("Failed to create default away automation rule");
      setfailure_dlg(true);
      toggle();
      return null;
    });
    if (defaultAwayReponse == null) {
      return false;
    }

    if (newInbox != null) {
      // Add Users to Inbox
      selectedUsers.forEach(async (userId: number) => {
        let addUser: IAddUserToInboxProps = {
          inboxId: newInbox ? newInbox.inboxId : 0,
          orgId: selectedOrg,
          userId: userId,
        };
        await AddUserToInbox(addUser);
      });
    }

    setdynamic_description("Inbox created successfully");
    setsuccess_dlg(true);
    toggle();
  };

  const selectStyle = {
    control: (provided: any) => ({
        ...provided,
        backgroundColor: Theme.styles.primary, // primary  
        border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
        color: Theme.styles.textColour1,   //textColor1
        borderColor: Theme.styles.primary
    }), 
    menu: (provided: any) => ({
        ...provided,
        backgroundColor: Theme.styles.primary, // primary  
        border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
        color: Theme.styles.textColour1,   //textColor1
    }), 
    menuList: (provided: any) => ({
        ...provided,
        backgroundColor: Theme.styles.primary, // primary  
        border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
        color: Theme.styles.textColour1,   //textColor1
    }), 
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
        return {
          ...styles,
          backgroundColor: isFocused ? Theme.styles.secondary : null,
          color: Theme.styles.textColour1,   //textColor1
        };
      }, 
      multiValue: (styles: any) => {
        return {
          ...styles,
          backgroundColor: Theme.styles.secondary,
        };
      },
      multiValueLabel: (styles: any) => ({
        ...styles,
        color: Theme.styles.textColour1,   //textColor1
      }),
      multiValueRemove: (styles: any) => ({
        ...styles,
        color: Theme.styles.textColour1,   //textColor1
        ':hover': {
          backgroundColor: Theme.styles.primary,
        },
      }),
    }

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefetch();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={props.modal}
        toggle={toggle}
        size="lg"
        onOpened={() => handleModalOpen()}
      >
        <ModalHeader toggle={toggle} tag="h4">
          Add New Inbox
        </ModalHeader>
        <ModalBody className="modelStyle">
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleValidInboxSubmit(values);
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>
                <div className="mb-3">
                  <Label>Select an Organisation</Label>
                  <Input
                    className="avInput"
                    id="selectOrg"
                    name="selectOrg"
                    type="select"
                    value={selectedOrg}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleOrgSelect(toNumber(e.currentTarget.value));
                    }}
                  >
                    {Array.isArray(organisations)
                      ? organisations.map((order: any, key: number) => (
                          <option key={key} value={order["OrganisationId"]}>
                            {order["Name"]}
                          </option>
                        ))
                      : null}
                  </Input>
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="inboxname"
                    label="Inbox Name"
                    type="text"
                    placeholder="Enter inbox name"
                    errorMessage="Invalid inbox name"
                    validate={{
                      required: { value: true },
                    }}
                    value={""}
                  />
                </div>


                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="inboxplan"
                    label="Inbox Plan"
                    type="select"
                    options={plans} 
                    value=""                   
                    errorMessage="Select plan"
                    validate={{
                    required: { value: true },
                    }}
                  >
                    <option value="" disabled>Select</option>
                   {plans.map((plan: any, index: any)=>{
                    return <option key={index}>{plan.label}</option>
                   })} 
                  </AvField>
                </div>



                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="apikey"
                    label="API Key"
                    placeholder="API Key from gupshup"
                    type="text"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Enter App API Key from GupShup",
                      },
                      minLength: {
                        value: 32,
                        errorMessage: "API Key must be 32 characters long",
                      },
                      maxLength: {
                        value: 32,
                        errorMessage: "API Key must be 32 characters long",
                      },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="appname"
                    label="App Name"
                    type="text"
                    placeholder="Enter App name from GupShup"
                    errorMessage="Invalid App Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={""}
                  />
                </div>
              </Col>
              <Col className="px-2">
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="partnerid"
                    label="Partner Id"
                    type="text"
                    placeholder="Enter App ID from GupShup"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter partner id from Gupshup",
                      },
                      pattern: {
                        value:
                          "^[A-Za-z0-9]{8}-([A-Za-z0-9]{4}-){3}[A-Za-z0-9]{12}$",
                        errorMessage:
                          "Should be in the format of 97067c1d-29d2-4303-ad0b-a92b81619ae9",
                      },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="partnertoken"
                    label="Partner Token"
                    type="text"
                    placeholder="Enter App ID Token from GupShup"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter partner token from Gupshup",
                      },
                      minLength: {
                        value: 35,
                        errorMessage:
                          "Partner Token must be 35 characters long",
                      },
                      maxLength: {
                        value: 35,
                        errorMessage:
                          "Partner Token must be 35 characters long",
                      },
                      pattern: {
                        value: "^sk_[a-z0-9]{32}$",
                        errorMessage:
                          "Should be in the format of sk_b3hd8f60eaf456db2a7e925b73a81f53",
                      },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="phonenumber"
                    label="Phone Number"
                    type="text"
                    placeholder="Enter Phone Number (ISO Format eg +44777777777)"
                    errorMessage="Invalid Phone Number"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter phone number",
                      },
                      pattern: {
                        value: "^\\+[0-9]{11,14}$",
                        errorMessage:
                          "Phone Number must be in the format +44777777777",
                      },
                    }}
                    value={""}
                  />
                </div>
                <div className="mb-3">
                  <label>Select Users</label>
                  <Select
                    isMulti
                    isClearable={true}
                    name="users"
                    placeholder="Select"
                    options={users}
                    onChange={(e: any) => handleUserSelect(e)}
                    styles={selectStyle}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default AddInbox;
