export const templateConfigs = {
  templateConfig: [
    {
      groupId: 1,
      groupName: "Common Templates",
      templates: [
        {
          templateId: 1,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "phone_follow_no_answer",
          templateText:
            "Hi {{1}}. I just tried calling you in regards to {{2}}. Can you let me know when it is convenient to speak, or alternatively, we could chat further here? Please confirm either way. Thanks, {{3}} from {{inboxName}}.",
          templateExample:
            "Hi [Paul]. I just tried calling you in regards to [the house viewing]. Can you let me know when it is convenient to speak, or alternatively, we could chat further here? Please confirm either way. Thanks, [Jane] from {{inboxName}}.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",          
        },
        {
          templateId: 2,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "phone_follow_when_answered",
          templateText:
            "Hi {{1}}. Thank you for the time on the phone just now in regards to {{2}}. If you have any further questions, then please let me know? Thanks, {{3}} from {{inboxName}}.",
          templateExample:
            "Hi [Paul]. Thank you for the time on the phone just now in regards to [the house viewing]. If you have any further questions, then please let me know? Thanks, [Jane] from {{inboxName}}.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 3,
          templateCategory: "MARKETING",
          templateType: "All",
          templateName: "previous_discussions",
          templateText:
            "Hi {{1}}. Just following up our previous discussions in regards to {{2}} Kind regards, {{3}}, {{inboxName}}",
          templateExample:
            "Hi [Bob]. Just following up our previous discussions in regards to [the rental of your property in Liverpool Street. Can you let me know when you want to discuss this further?] Kind regards, [Paul], {{inboxName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",          
        },
        {
          templateId: 4,
          templateCategory: "MARKETING",
          templateType: "All",
          templateName: "can_we_chat",
          templateText:
            "Hi {{1}}. I was hoping to be able to have a quick catch up about {{2}} Can we arrange a time to discuss further, or maybe chat here? Whichever works best for you. Kind regards {{3}} from {{inboxName}}.",
          templateExample:
            "Hi [Paul]. I was hoping to be able to have a quick catch up about [the house viewing] Can we arrange a time to discuss further, or maybe chat here? Whichever works best for you. Kind regards [Jane] from {{inboxName}}.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",          
        },
        {
          templateId: 6,
          templateCategory: "MARKETING",
          templateType: "Presubscribers Only",
          templateName: "invite",
          templateText:
            "You have been invited to a WhatsApp conversation by {{1}} at {{inboxName}} in regards to {{2}}. Please respond 👇 with *LET'S CHAT* to continue, or *STOP* will unsubscribe you from future WhatsApp messages",
          templateExample:
            "You have been invited to a WhatsApp conversation by [Paul] at {{inboxName}} in regards to [the house viewing]. Please respond 👇 with *LET'S CHAT* to continue, or *STOP* will unsubscribe you from future WhatsApp messages",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",   
          buttons: "[{\"type\":\"QUICK_REPLY\",\"text\":\"LET'S CHAT\"},{\"type\":\"QUICK_REPLY\",\"text\":\"STOP\"}]"       
        },
      ],
    },
    {
      groupId: 2,
      groupName: "Natural Chat Templates",
      templates: [
        {
          templateId: 52,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "natural_chat_text",
          templateText:
            "_You have been sent an update:_\n\n{{1}}\n\n_{{inboxName}}_",
          templateExample:
            "_You have been sent an update:_\n\n[You order has been shipped]\n\n_{{inboxName}}_",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",          
        },
        {
          templateId: 53,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "natural_chat_media",
          templateText:
            "_You have been sent an update:_\n\n{{1}}\n\n_To receive the attachment please respond *OK*_\n\n_{{inboxName}}_",
          templateExample:
            "_You have been sent an update:_\n\n[Please find attached the property image as request]\n\n_To receive the attachment please respond *OK*_\n\n_{{inboxName}}_",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",          
        },
        {
          templateId: 5,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "natural_chat",
          templateText:
            "There is a message waiting for you, sent by {{1}} at {{orgName}}. Please respond with OK to retrieve and view this message.",
          templateExample:
            "There is a message waiting for you, sent by [Paul] at {{orgName}}. Please respond with OK to retrieve and view this message.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
      ],
    },
    {
      groupId: 3,
      groupName: "Estate Agent Common Templates",
      templates: [
        {
          templateId: 100,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "ma_follow_up",
          templateText:
            "Hi {{1}}, Thank you for your recent enquiry regarding a valuation request for {{2}}. We would like to discuss this further and arrange a valuation for you. Can we arrange a time to discuss further or chat on here. Kind Regards {{3}}",
          templateExample:
            "Hi {{Mr Bloggs}}, Thank you for your recent enquiry regarding a valuation request for [21 Test Street]. We would like to discuss this further and arrange a valuation for you. Can we arrange a time to discuss further or chat on here. Kind Regards {{Sarah}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 101,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "viewing_follow_up_best_and_finals",
          templateText:
            "Hi {{1}}, we are just getting in touch about your viewing which took place on {{2}}, we are in receipt of multiple offers and are proceeding to best and final offers by {{3}}, please do let us know if you are interested and if we can do anymore for you. Many thanks, the team @{{orgName}}",
          templateExample:
            "Hi [James], we are just getting in touch about your viewing which took place on [Monday 12th October], we are in receipt of multiple offers and are proceeding to best and final offers by [1pm, Weds 14th October], please do let us know if you are interested and if we can do anymore for you. Many thanks, the team @{{orgName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 102,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "val_response",
          templateText:
            "Hi {{1}}, its {{2}} here from {{orgName}}. Thank you for submitting your valuation request {{3}} for your {{4}}. I can confirm I've looked at your details submitted and I estimate your property {{5}}. If you have any other questions, please feel free to message me at anytime?",
          templateExample:
            "Hi [Paul], its [Tom] here from {{orgName}}. Thank you for submitting your valuation request [this morning] for your [2 bed house]. I can confirm I've looked at your details submitted and I estimate your property [to be worth around £750,000, however, if you have any more recent photo's to send, please can you send them to me]. If you have any other questions, please feel free to message me at anytime?",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 103,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "vendor_followup",
          templateText:
            "Hi {{1}}, We carried out a valuation at your property {{2}}. Is this something that you're still needing any assistance from us on?\nPlease reply 👇\n👉 *LET'S CHAT* - if you want to explore further\n👉 *NO LONGER INTERESTED* - to let us know you're not looking to goto market anytime soon\n👉 *STOP* - to unsubscribe from any further WhatsApp messages from us\nKind regards, {{3}}, {{inboxName}}",
          templateExample:
            "Hi [Paul], We carried out a valuation at your property [yesterday]. Is this something that you're still needing any assistance from us on?\nPlease reply 👇\n👉 *LET'S CHAT* - if you want to explore further\n👉 *NO LONGER INTERESTED* - to let us know you're not looking to goto market anytime soon\n👉 *STOP* - to unsubscribe from any further WhatsApp messages from us\nKind regards, [Jack], {{inboxName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },
        {
          templateId: 104,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "vendor_feedback",
          templateText:
            "Hi {{1}}, \nWe’ve managed to get feedback from {{2}} who viewed on {{3}} at {{4}}. Please find feedback below: \n{{5}} \nWe will be in touch with any further updates and viewings. \nKind Regards, {{6}}, {{inboxName}}",
          templateExample:
            "Hi [James], \nWe’ve managed to get feedback from [John] who viewed on [21st December] at [14:00]. Please find feedback below: \n[Lovely property and we think will sell well.] \nWe will be in touch with any further updates and viewings. \nKind Regards, [Brian], {{inboxName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },
        {
          templateId: 105,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "vendor_viewing_request",
          templateText:
            "Hi {{1}}, I hope you are well. Please can we do a viewing at your property {{2}} on the {{3}} at {{4}}? Please let us know if this is okay and we can get it confirmed in the diary. Thank you and speak soon!",
          templateExample:
            "Hi [John], I hope you are well. Please can we do a viewing at your property [12 The Avenue] on the [21st Dec] at [14:00]? Please let us know if this is okay and we can get it confirmed in the diary. Thank you and speak soon!",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },
        {
          templateId: 106,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "vendor_viewing_request_2",
          templateText:
            "Hi {{1}},\nI trust this message finds you well.\nI'm reaching out to see if we can book a viewing for {{2}}. The proposed time is {{3}} on {{4}}.\nThe viewer's position is – {{5}}.\nYour prompt response will be greatly appreciated. Kindly let me know if we can proceed with booking the viewing.\nThank you.",
          templateExample:
            "Hi [John],\nI trust this message finds you well.\nI'm reaching out to see if we can book a viewing for [your property]. The proposed time is [14:00] on [21st December].\nThe viewer's position is – [currently have an offer on their property].\nYour prompt response will be greatly appreciated. Kindly let me know if we can proceed with booking the viewing.\nThank you.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },
        {
          templateId: 107,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "viewing_feedback_buttons",
          templateText:
            "Dear {{1}}\nYou viewed {{2}} with us. We would be grateful if you could let us know your thoughts on the property - condition, layout, price etc. Any other information? \nLike to place an offer 🏠💷?",
          templateExample:
            "Dear [John\nYou viewed [12 The Avenue] with us. We would be grateful if you could let us know your thoughts on the property - condition, layout, price etc. Any other information? \nLike to place an offer 🏠💷?",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          buttons: "[{\"type\":\"QUICK_REPLY\",\"text\":\"I'M INTERESTED\"},{\"type\":\"QUICK_REPLY\",\"text\":\"NOT FOR ME\"},{\"type\":\"QUICK_REPLY\",\"text\":\"STOP\"}]"   
        },
        {
          templateId: 108,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "viewing_feedback",
          templateText:
            "Hi {{1}}. Thank you for choosing {{orgName}} for your recent viewing! \nWe would be grateful if you would provide some feedback on the property that you visited or if you would like to apply. Please reply and our team will get in touch. \nThank you. {{2}} from {{inboxName}}",
          templateExample:
            "Hi [John]. Thank you for choosing {{orgName}} for your recent viewing! \nWe would be grateful if you would provide some feedback on the property that you visited or if you would like to apply. Please reply and our team will get in touch. \nThank you. [Brian] from {{inboxName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },
        {
          templateId: 109,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "vendor_cancellation",
          templateText:
            "Dear {{1}}, I have tried to call you just now, but couldn't reach you. Unfortunately, we have just received {{2}} to advise the viewer cannot attend the viewing due to {{3}}. I will try and call you again. Sorry it's not the best news today. Please respond to let me know you’ve received my message. Kind regards {{4}}, {{inboxName}}.",
          templateExample:
            "Dear [Simon], I have tried to call you just now, but couldn't reach you. Unfortunately, we have just received [a message] to advise the viewer cannot attend the viewing due to [illness]. I will try and call you again. Sorry it's not the best news today. Please respond to let me know you’ve received my message. Kind regards [Jack], {{inboxName}}.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },
        {
          templateId: 110,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "vendor_viewing_confirmation",
          templateText:
            "Hi {{1}},\nI hope this message finds you well.\nI'm reaching out to confirm that the viewing appointment has been successfully booked for you. The details are as follows:\n- Date: {{2}}\n- Time: {{3}}\n- Viewers: {{4}}\n- Agent: {{5}}\nWe of course look to get you feedback as soon as we can.\nKind Regards,{{inboxName}}",
          templateExample:
            "Hi [Lucy],\nI hope this message finds you well.\nI'm reaching out to confirm that the viewing appointment has been successfully booked for you. The details are as follows:\n- Date: [12st December]\n- Time: [14:00]\n- Viewers: [Mr and Mrs Smith]\n- Agent: [Brian]\nWe of course look to get you feedback as soon as we can.\nKind Regards,{{inboxName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },
        {
          templateId: 111,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "landlord_viewing_update",
          templateText:
            "Hi {{1}},\nI will be showing the viewer {{2}}, at your property at {{3}}.\nDate: {{4}}\nTime: {{5}}\nI will be in touch after the viewing with feedback.",
          templateExample:
            "Hi [John],\nI will be showing the viewer [Mrs Jones], at your property at [12 The Avenue].\nDate: [21st December]\nTime: [14:00]\nI will be in touch after the viewing with feedback.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },{
          templateId: 112,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "landlord_rent_chase_update",
          templateText:
            "Hello,\nJust to let you know we are continuing to chase your tenants at {{1}} for overdue rent and will update you as soon as this has been paid. \nIf you have any concerns please let us know and we can arrange for one of the team to contact you. \nRegards {{inboxName}}",
          templateExample:
            "Hello,\nJust to let you know we are continuing to chase your tenants at [12 The Avenue] for overdue rent and will update you as soon as this has been paid. \nIf you have any concerns please let us know and we can arrange for one of the team to contact you. \nRegards {{inboxName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },{
          templateId: 113,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "viewing_awaiting",
          templateText:
            "Hi {{1}},\nI wanted to keep you in the loop regarding {{2}}. I've reached out to the owner and requested a viewing on {{3}} at {{4}}. We are currently awaiting confirmation, and once we hear back, I will promptly get in touch with you.\nThank you for your patience, and I'll be sure to keep you updated.",
          templateExample:
            "Hi [Jill],\nI wanted to keep you in the loop regarding [12 The Avenue]. I've reached out to the owner and requested a viewing on [21st December] at [14:00]. We are currently awaiting confirmation, and once we hear back, I will promptly get in touch with you.\nThank you for your patience, and I'll be sure to keep you updated.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },{
          templateId: 114,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "viewing_confirmation_today",
          templateText:
            "You have a viewing booked today at {{1}}. Are you still able to attend?",
          templateExample:
            "You have a viewing booked today at {{1}}. Are you still able to attend?",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          buttons: "[{\"type\":\"QUICK_REPLY\",\"text\":\"YES, I’LL BE THERE\"},{\"type\":\"QUICK_REPLY\",\"text\":\"I NEED TO RESCHEDULE\"}]"  
        },{
          templateId: 115,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "tenant_rent_chase",
          templateText:
            "Hello,\nYour rent is now over due at {{1}} and payment is required immediately.\nIf you are having difficulty paying your rent please let us know and we can arrange for one of the team to contact you.\nRegards,\n{{inboxName}}",
          templateExample:
            "Hello,\nYour rent is now over due at [12 The Avenue] and payment is required immediately.\nIf you are having difficulty paying your rent please let us know and we can arrange for one of the team to contact you.\nRegards,\n{{inboxName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },
        {
          templateId: 116,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "tenant_rent_chase_2",
          templateText:
            "Hi {{1}}. We're reaching out to you in regards to the outstanding amount of {{2}}, which was due {{3}}. Please let us know when this account will be settled? Kind regards, {{4}}, {{inboxName}}",
          templateExample:
            "Hi [John]. We're reaching out to you in regards to the outstanding amount of [12 The Avenue], which was due [last week]. Please let us know when this account will be settled? Kind regards, [Brian], {{inboxName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },
        {
          templateId: 117,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "viewing_appointment_confirmation",
          templateText:
            "Hi {{1}},\nI hope this message finds you well.\nI'm reaching out to confirm that the viewing appointment has been successfully booked for you. The details are as follows:\nDate: {{2}}\nTime: {{3}}\nViewers: {{4}}\nAgent: {{5}}\nWe of course look to get you feedback as soon as we can.\nKind Regards, {{inboxName}}",
          templateExample:
            "Hi [Brian],\nI hope this message finds you well.\nI'm reaching out to confirm that the viewing appointment has been successfully booked for you. The details are as follows:\nDate: [21st December]\nTime: [14:00]\nViewers: [Mr nd Mrs Smith]\nAgent: [Jack]\nWe of course look to get you feedback as soon as we can.\nKind Regards, {{inboxName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          //buttons: 
        },
        {
          templateId: 118,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "inspection_confirmation_today",
          templateText:
            "*Property Inspection Today*\nHello we are confirming your property inspection appointment today. \nPlease respond 👇 \nThank you | {{inboxName}}",
          templateExample:
          "*Property Inspection Today*\nHello we are confirming your property inspection appointment today. \nPlease respond 👇 \nThank you | {{inboxName}}",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          buttons: "[{\"type\":\"QUICK_REPLY\",\"text\":\"YES, I’LL BE THERE\"},{\"type\":\"QUICK_REPLY\",\"text\":\"SORRY, CAN’T MAKE IT\"}]"  
        },
        {
          templateId: 119,
          templateCategory: "UTILITY",
          templateType: "All",
          templateName: "property_inspection_request",
          templateText:
            "Hi {{1}}, I've noticed you are overdue a property inspection. Are you available {{2}}? \nThe appointment would take no more than 15 minutes. If you can confirm and I can get that booked in for you? Please respond👇",
          templateExample:
            "Hi [John], I've noticed you are overdue a property inspection. Are you available [tomorrow at 14:00]? \nThe appointment would take no more than 15 minutes. If you can confirm and I can get that booked in for you? Please respond👇",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
          buttons: "[{\"type\":\"QUICK_REPLY\",\"text\":\"ACCEPT\"},{\"type\":\"QUICK_REPLY\",\"text\":\"ANOTHER TIME\"}]"  
        },
      ],
    },
    {
      groupId: 4,
      groupName: "Estate Agent Autoresponder Templates",
      templates: [
        {
          templateId: 200,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "lettings_responder_rightmove",
          templateText:
            "Hi {{1}}, further to your enquiry for the {{2}} on {{3}} ({{4}}), we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 B - to book a viewing on a property you have seen listed for rent\n 👉 Q - if you want to ask a general question about a property\n 👉 L - if you want to let your property\n\n To unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul], further to your enquiry for the [2 bed house] on [liverpool street] ([https://rightmove.com]), we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 B - to book a viewing on a property you have seen listed for rent\n 👉 Q - if you want to ask a general question about a property\n 👉 L - if you want to let your property\n\n To unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 201,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "sales_responder_rightmove",
          templateText:
            "Hi {{1}}, further to your enquiry for the {{2}} on {{3}} ({{4}}), we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 V - to book a viewing on a property you have seen listed\n 👉 S - if you want to sell your property\n 👉 A - if you want to ask a general question about a property\n\nTo unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul], further to your enquiry for the [2 bed house] on [liverpool street] ([https://rightmove.com]), we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 V - to book a viewing on a property you have seen listed\n 👉 S - if you want to sell your property\n 👉 A - if you want to ask a general question about a property\n\nTo unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 202,
          templateCategory: "TRANSUTILITYACTIONAL",
          templateType: "Automated Replies",
          templateName: "lettings_zoopla_responder",
          templateText:
            "Hi {{1}}, further to your enquiry for the {{2}} on {{3}}, we’re sending you a one time message in WhatsApp to seek permission to chat further about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 B - to book a viewing on a property you have seen listed for rent\n 👉 Q - if you want to ask a general question about a property\n 👉 L - if you want to let your property\n\n To unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul], further to your enquiry for the [2 bed house] on [liverpool street], we’re sending you a one time message in WhatsApp to seek permission to chat further about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 B - to book a viewing on a property you have seen listed for rent\n 👉 Q - if you want to ask a general question about a property\n 👉 L - if you want to let your property\n\n To unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 203,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "sales_zoopla_responder",
          templateText:
            "Hi {{1}}, further to your enquiry for the {{2}} on {{3}}, we’re sending you a one time message in WhatsApp to seek permission to chat further here about your property enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 V - to book a viewing on a property you have seen listed\n 👉 S - if you want to sell your property\n 👉 A - if you want to ask a general question about a property\n\nTo unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul], further to your enquiry for the [2 bed house] on [liverpool street], we’re sending you a one time message in WhatsApp to seek permission to chat further here about your property enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 V - to book a viewing on a property you have seen listed\n 👉 S - if you want to sell your property\n 👉 A - if you want to ask a general question about a property\n\nTo unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 204,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "lettings_responder_otm",
          templateText:
            "Hi {{1}}, further to your enquiry for the {{2}} on {{3}}, we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 B - to book a viewing on a property you have seen listed for rent\n 👉 Q - if you want to ask a general question about a property\n 👉 L - if you want to let your property\n\n To unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul], further to your enquiry for the [2 bed house] on [liverpool street], we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 B - to book a viewing on a property you have seen listed for rent\n 👉 Q - if you want to ask a general question about a property\n 👉 L - if you want to let your property\n\n To unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 205,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "sales_responder_otm",
          templateText:
            "Hi {{1}}, further to your enquiry for the {{2}} on {{3}}, we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 V - to book a viewing on a property you have seen listed\n 👉 S - if you want to sell your property\n 👉 A - if you want to ask a general question about a property\n\nTo unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul], further to your enquiry for the [2 bed house] on [liverpool street], we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry.\n\nPlease reply using one of the keywords below 👇\n\n 👉 V - to book a viewing on a property you have seen listed\n 👉 S - if you want to sell your property\n 👉 A - if you want to ask a general question about a property\n\nTo unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 206,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "phone_auto_responder",
          templateText:
            "Sorry we missed your call.\n\nIn order to assist you with your enquiry, please reply with one of the numbers 👇\n\n 👉 1 - for sales related enquires\n 👉 2 - for a lettings related enquiry\n 👉 3 - for a book a mortgage appointment\n 👉 4 - if you rent a property from {{inboxName}} and would like to report an issue\n 👉 5 - for all other enquiries\n\nOr, if you are already dealing with a member of the team, please just continue your chat here.",
          templateExample:
            "Sorry we missed your call.\n\nIn order to assist you with your enquiry, please reply with one of the numbers 👇\n\n 👉 1 - for sales related enquires\n 👉 2 - for a lettings related enquiry\n 👉 3 - for a book a mortgage appointment\n 👉 4 - if you rent a property from {{inboxName}} and would like to report an issue\n 👉 5 - for all other enquiries\n\nOr, if you are already dealing with a member of the team, please just continue your chat here.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 102,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "portal_responder",
          templateText:
            "Hi {{1}}. Further to your enquiry for the {{2}} on {{3}}, we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry. To grant permission and chat with the branch, please reply with CHAT. To unsubscribe reply with STOP.",
          templateExample:
            "Hi [Paul]. Further to your enquiry for the [3 bedroom house] on [liverpool street], we’re sending you a one time message in WhatsApp to seek permission to chat further here about your enquiry. To grant permission and chat with the branch, please reply with CHAT. To unsubscribe reply with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 105,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "valpal_responder_v2",
          templateText:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at {{2}}, which {{3}}. To continue to chat with a member of the team, please respond {{4}}, or should you want to unsubscribe at anytime, please respond with STOP.",
          templateExample:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at [your property], which [has a valuation of £475,000]. To continue to chat with a member of the team, please respond [with S if you would like to discuss selling your property], or should you want to unsubscribe at anytime, please respond with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 106,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "leadpro_lettings_responder",
          templateText:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at {{2}}, which shows an expected rental figure of upto {{3}} per month. To continue to chat with a member of the team, please respond to this message with the letter L. Should you want to unsubscribe at anytime, please respond with STOP.",
          templateExample:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at [your property], which shows an expected rental figure of upto [has a valuation of £475,000] per month. To continue to chat with a member of the team, please respond to this message with the letter L. Should you want to unsubscribe at anytime, please respond with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 107,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "leadpro_sales_val_responder",
          templateText:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at {{2}}, which shows an expected sales valuation of upto {{3}}. To continue to chat with a member of the team, please respond to this message with the letter S. Should you want to unsubscribe at anytime, please respond with STOP.",
          templateExample:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at [your property], which shows an expected sales valuation of upto [has a valuation of £475,000]. To continue to chat with a member of the team, please respond to this message with the letter S. Should you want to unsubscribe at anytime, please respond with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
        {
          templateId: 109,
          templateCategory: "UTILITY",
          templateType: "Automated Replies",
          templateName: "rightmove_val_responder",
          templateText:
            "Hi {{1}}. Thank you for submitting a valuation request for your property at {{2}}. To continue to chat with a member of the team, please respond with the word VAL, or should you want to unsubscribe at anytime, please respond with STOP.",
          templateExample:
            "Hi [Paul]. Thank you for submitting a valuation request for your property at [liverpool street]. To continue to chat with a member of the team, please respond with the word VAL, or should you want to unsubscribe at anytime, please respond with STOP.",
          attachmentType: "None",
          //footer: "_To unsubscribe please respond with STOP_",
        },
      ],
    },
  ],
};
