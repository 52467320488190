import { gql } from "@apollo/client";

export const GET_API_PROVIDER = gql`
  query MyQuery {
    msgbox_APIProvider(where: { Name: { _nlike: "gupshup" } }) {
      APIProviderId
    }
  }
`;

export const GET_USER_ORGADMIN_ROLE = gql`
  query MyQuery {
    msgbox_Role(where: { Name: { _eq: "OrgAdmin" } }) {
      RoleId
    }
  }
`;

export const GET_ORGANISATIONS = gql`
  query MyQuery {
    msgbox_Organisation(order_by: { Name: asc }) {
      OrganisationId
      Name
    }
  }
`;

export const GET_ROLES = gql`
	query getRoles {
		msgbox_Role {
			Name
			Description
			RoleId
		}
	}
`;

export const GET_INBOXES = gql`
  query get_inboxes($org: Int) {
    msgbox_Inbox(where: {
      OrganisationId:{_eq:$org}
    }){
      Name
      InboxId
    }
  }
`


export const ADD_USER = gql`
	mutation addUser(
		$email: String!
		$firstName: String!
		$lastName: String!
    $org: Int!
		$role: msgbox_UserRole_arr_rel_insert_input!
		$inboxes: msgbox_UserInbox_arr_rel_insert_input!
	) {
		insert_msgbox_User(
			objects: {
				EMail: $email
				FirstName: $firstName
				LastName: $lastName
				UserRoles: $role
				UserInboxes: $inboxes
        OrganisationId: $org
			}
		) {
			returning {
      UserId
      }
	}
  }
`;


export const GET_EMAILS = gql`
  query MyQuery {
    msgbox_User(order_by: {UserId: desc}, where: {IsDeleted: {_eq: false}}) {
      UserId
      EMail
    }
  }
`;


export const GET_TEAMS = gql`
query MyQuery ($orgId: Int) {
  msgbox_Department(where: {OrganisationId: {_eq: $orgId}, IsDeleted: {_eq: false}},order_by: {Name: asc}) {
    DepartmentId
    Name
  }
}
`


export const ADD_USER_TO_TEAM = gql`
mutation MyMutation(
$orgId: Int
$userId: Int
$departmentId: Int
) 
  {
    insert_msgbox_UserDepartment(
    objects: 
    {
     DepartmentId: $departmentId, 
     OrganisationId: $orgId, 
     UserId: $userId
    }
    )
    {
    affected_rows
    }

  }
`