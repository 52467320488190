import React, { Dispatch, SetStateAction, useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import Select, { OnChangeValue } from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import client from "../../apollo";
import SweetAlert from "react-bootstrap-sweetalert";
import {GET_INBOX_PLANS} from "src/common/inboxUtility";
import { GET_USERS } from "../AddInbox/query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ThemeContext } from "src/helpers/themeContext";
import { CHECK_USER_INBOX, DELETE_USER_INBOX, GET_INBOX_DETAILS, INSERT_USER_INBOX, REMOVE_USER_INBOX, UPDATE_API_ACCOUNT_DETAILS, UPDATE_INBOX_DETAILS } from "./EditInboxName.query";
interface IProps {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  onRefetch: any;
  inboxId: any;
  orgId?: number;
  orgName: any;
}

const EditInbox = (props: IProps) => {
  const handleShow = () => {
    props.setModal(false);
  };
  const toggle = () => {
    props.setModal(!props.modal);
  };
  const Theme = useContext(ThemeContext)
  const [deleteUserInbox] = useMutation(DELETE_USER_INBOX)
  const [insertUserInbox] = useMutation(INSERT_USER_INBOX)

  const [dynamic_description, setdynamic_description] = useState("");
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [failure_dlg, setfailure_dlg] = useState(false);
  const [loading, setLoading] = useState(true)

  const [inboxName, setInboxName] = useState("")
  const [users, setUsers] = useState<{ value: any; label: string }[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [initialUsers, setInitialUsers] = useState<any>([])

  const [orgId, setOrgId] = useState<any>(-1)
  const [plans, setPlans] = useState<any>([])
  const [selectedPlan, setSelectedPlan] = useState("")
  
  const [apiAccountId, setApiAccountId] = useState<any>()
  const [apiKey, setApiKey] = useState<any>()
  const [appName, setAppName] = useState("")
  const [partnerId, setPartnerId] = useState<any>()
  const [partnerToken, setPartnerToken] = useState<any>()
  const [phoneNumber, setPhoneNumber] = useState<any>()


  const {data: planData} = useQuery(GET_INBOX_PLANS);
  const {data: inboxData} = useQuery(GET_INBOX_DETAILS,{
    variables:{
      inboxId: props.inboxId
    },
    fetchPolicy: "no-cache",
  });
  const [updateInboxDetails] = useMutation(UPDATE_INBOX_DETAILS)
  const [updateApiDetails]  = useMutation(UPDATE_API_ACCOUNT_DETAILS)
 

  useEffect(()=>{
    if(inboxData){
    loadUsers(inboxData?.msgbox_Inbox[0]?.OrganisationId)
    setOrgId(inboxData?.msgbox_Inbox[0]?.OrganisationId)

    //if 'legacy' plan account, set this as an option / the default in plans dropdown 
    if(inboxData?.msgbox_Inbox[0]?.Plan.PlanId===6){
      setPlans((prevPlans: any) => [...prevPlans, {value: 6, label: inboxData?.msgbox_Inbox[0]?.Plan.Name}])
    }
    setSelectedPlan(inboxData?.msgbox_Inbox[0]?.Plan.Name.trim())
    setApiAccountId(inboxData?.msgbox_Inbox[0]?.APIAccount?.APIAccountId)
    setApiKey(inboxData?.msgbox_Inbox[0]?.APIAccount?.Key)
    setAppName(inboxData?.msgbox_Inbox[0]?.APIAccount?.AppName)
    setPartnerId(inboxData?.msgbox_Inbox[0]?.APIAccount?.PartnerId)
    setPartnerToken(inboxData?.msgbox_Inbox[0]?.APIAccount?.PartnerToken)
    setPhoneNumber(inboxData?.msgbox_Inbox[0]?.APIAccount?.PhoneNumber)

    let initialUserList: { value: any; label: string; }[] = []
    inboxData?.msgbox_Inbox[0]?.UserInboxes.forEach((userInbox: any) => {
        initialUserList.push(
          {
            value: userInbox.User.UserId,
            label: userInbox.User.FirstName + " " + userInbox.User.LastName
          }
        )
    });
    setSelectedUsers(initialUserList)

    setInitialUsers(initialUserList)
    setInboxName(inboxData?.msgbox_Inbox[0]?.Name)
    }
  },[inboxData])

  useEffect(()=>{
    if(inboxName){
      setLoading(false)
    }
  },[inboxName])

  useEffect(()=>{
    if(planData){
      let newArray = [];
      for (let index = 0; index < planData.msgbox_Plan.length; index++) {
        const element = planData.msgbox_Plan[index];
        newArray.push({
          value: element.PlanId,
          label: element.Name,
        });
      }
      setPlans(newArray)
    }
  },[planData])


  const loadUsers = async (orgId: number) => {
    const users = await client.query({
      query: GET_USERS,
      variables: {
        orgId: orgId,
      },
    });
    const userData = users?.data?.msgbox_User || [];

    let options: { value: any; label: string }[] = [];
    userData.forEach((element: any) => {
      const name = element.FirstName + " " + element.LastName;
      const data = { value: element.UserId, label: name };
      options.push(data);
    });

    setUsers(options);
  };


  const handleValidInboxSubmit = async (values: any) => {
    
    try{
      const planToAdd = plans.find((plan: any)=>{
        return plan.label.trim()===values.inboxplan.trim()
      })

      // Update inbox(name & planId)
      await updateInboxDetails({
        variables:{
          inboxId: props.inboxId,
          inboxName: values.inboxname,
          planId: planToAdd.value
        }
      })

      //Update the APIAccount associated with the inbox
      await updateApiDetails({
        variables: {
          apiAccountId: apiAccountId,
          appName: values.appname,
          apiKey: values.apikey,
          partnerId: values.partnerid,
          partnerToken: values.partnertoken,
          phoneNumber: values.phonenumber,
          name: values.inboxname
        }
      })
    
      //Update UserInboxes 
      let removeList: any[] = []
      let insertList: any[] = []
      insertList = selectedUsers.filter((user: any) => !initialUsers.includes(user));
      removeList = initialUsers.filter((user: any) => !selectedUsers.includes(user));

      removeList.map(async (userToRemove)=>{
      await deleteUserInbox({
          variables: {
            inboxId: props.inboxId,
            userId: userToRemove.value
          }
        })
      })
      
      insertList.map(async (userToInsert)=>{
      await insertUserInbox({
          variables:{
            inboxId: props.inboxId,
            orgId: orgId,
            userId: userToInsert.value
          }
        })
      })
      setdynamic_description("Inbox edited successfully");
      setsuccess_dlg(true);
      toggle();
    }
    catch(e){
      console.log(e)
      setdynamic_description("Oops! Something went wrong.");
      setfailure_dlg(true);
      toggle();
    }
  };

  const selectStyle = {
    control: (provided: any) => ({
        ...provided,
        backgroundColor: Theme.styles.primary, // primary  
        border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
        color: Theme.styles.textColour1,   //textColor1
        borderColor: Theme.styles.primary
    }), 
    menu: (provided: any) => ({
        ...provided,
        backgroundColor: Theme.styles.primary, // primary  
        border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
        color: Theme.styles.textColour1,   //textColor1
    }), 
    menuList: (provided: any) => ({
        ...provided,
        backgroundColor: Theme.styles.primary, // primary  
        border: `1px solid ${Theme.styles.primaryBold}`, //primary bold
        color: Theme.styles.textColour1,   //textColor1
    }), 
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
        return {
          ...styles,
          backgroundColor: isFocused ? Theme.styles.secondary : null,
          color: Theme.styles.textColour1,   //textColor1
        };
      }, 
      multiValue: (styles: any) => {
        return {
          ...styles,
          backgroundColor: Theme.styles.secondary,
        };
      },
      multiValueLabel: (styles: any) => ({
        ...styles,
        color: Theme.styles.textColour1,   //textColor1
      }),
      multiValueRemove: (styles: any) => ({
        ...styles,
        color: Theme.styles.textColour1,   //textColor1
        ':hover': {
          backgroundColor: Theme.styles.primary,
        },
      }),
    }

  return (
    <React.Fragment>
      {success_dlg ? (
        <SweetAlert
          success
          title={Theme.name}
          onConfirm={() => {
            setsuccess_dlg(false);
            props.onRefetch();
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      {failure_dlg ? (
        <SweetAlert
          error
          title={Theme.name}
          onConfirm={() => {
            setfailure_dlg(false);
          }}
        >
          {dynamic_description}
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={props.modal}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader toggle={toggle} tag="h4">
          Edit Inbox 
        </ModalHeader>
        <ModalBody className="modelStyle">


          {loading ? <p>Loading inbox data...</p>
          :
          <AvForm
            onValidSubmit={(e: any, values: any) => {
              handleValidInboxSubmit(values);
            }}
          >
            <Row form className="d-flex flex-row mb-3">
              <Col>
                <div className="mb-3">
                  <Label>Organisation</Label>
                  <AvField
                    disabled
                    className="avInput"
                    id="selectOrg"
                    name="Organisation"
                    type="text"
                    value={props.orgName}
                  >
                  </AvField>
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="inboxname"
                    label="Inbox Name"
                    type="text"
                    errorMessage="Invalid inbox name"
                    validate={{
                      required: { value: true },
                    }}
                    value={inboxName}
                  />
                </div>


                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="inboxplan"
                    label="Inbox Plan"
                    type="select"
                    options={plans} 
                    value={selectedPlan}                   
                    errorMessage="Select plan"
                    validate={{
                    required: { value: true },
                    }}
                  >
                  {plans.map((plan: any, index: any)=>{
                  return (
                  <option key={index}>
                    {plan.label}
                  </option>)
                   })} 
                  </AvField>
                </div>



                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="apikey"
                    label="API Key"
                    placeholder="API Key from gupshup"
                    type="text"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Enter App API Key from GupShup",
                      },
                      minLength: {
                        value: 32,
                        errorMessage: "API Key must be 32 characters long",
                      },
                      maxLength: {
                        value: 32,
                        errorMessage: "API Key must be 32 characters long",
                      },
                    }}
                    value={apiKey}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="appname"
                    label="App Name"
                    type="text"
                    placeholder="Enter App name from GupShup"
                    errorMessage="Invalid App Name"
                    validate={{
                      required: { value: true },
                    }}
                    value={appName}
                  />
                </div>
              </Col>
              <Col className="px-2">
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="partnerid"
                    label="Partner Id"
                    type="text"
                    placeholder="Enter App ID from GupShup"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter partner id from Gupshup",
                      },
                      pattern: {
                        value:
                          "^[A-Za-z0-9]{8}-([A-Za-z0-9]{4}-){3}[A-Za-z0-9]{12}$",
                        errorMessage:
                          "Should be in the format of 97067c1d-29d2-4303-ad0b-a92b81619ae9",
                      },
                    }}
                    value={partnerId}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="partnertoken"
                    label="Partner Token"
                    type="text"
                    placeholder="Enter App ID Token from GupShup"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter partner token from Gupshup",
                      },
                      minLength: {
                        value: 35,
                        errorMessage:
                          "Partner Token must be 35 characters long",
                      },
                      maxLength: {
                        value: 35,
                        errorMessage:
                          "Partner Token must be 35 characters long",
                      },
                      pattern: {
                        value: "^sk_[a-z0-9]{32}$",
                        errorMessage:
                          "Should be in the format of sk_b3hd8f60eaf456db2a7e925b73a81f53",
                      },
                    }}
                    value={partnerToken}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    className="avInput"
                    name="phonenumber"
                    label="Phone Number"
                    type="text"
                    placeholder="Enter Phone Number (ISO Format eg +44777777777)"
                    errorMessage="Invalid Phone Number"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Please enter phone number",
                      },
                      pattern: {
                        value: "^\\+[0-9]{11,14}$",
                        errorMessage:
                          "Phone Number must be in the format +44777777777",
                      },
                    }}
                    value={phoneNumber}
                  />
                </div>
                <div className="mb-3">
                  <label>Select Users</label>
                  <Select
                    isMulti
                    name="users"
                    placeholder="Select"
                    options={users}
                    value={selectedUsers}
                    onChange={(e: any) => {
                      setSelectedUsers(e);
                    }}
                    styles={selectStyle}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-light w-sm"
                    onClick={handleShow}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
          }
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EditInbox;
