import { gql } from '@apollo/client';

export const UPDATE_INBOX_NAME = gql`
mutation MyMutation($Name: String!, $InboxId: Int!, $APIAccountId: Int!) {
  update_msgbox_Inbox(where: {InboxId: {_eq: $InboxId}}, _set: {Name: $Name}) {
    affected_rows
  }
  update_msgbox_APIAccount(where: {APIAccountId: {_eq: $APIAccountId}}, _set: {Name: $Name}) {
    affected_rows
  }
}
`;


export const GET_INBOX_DETAILS = gql`
query MyQuery ($inboxId: Int!) {
  msgbox_Inbox(where: {InboxId: {_eq: $inboxId}}) 
  {
    OrganisationId
    Name
    Plan 
    {
      PlanId
      Name
    }
    APIAccount 
    {
      APIAccountId
      PartnerId
      PartnerToken
      PhoneNumber
      AppName
      Key
    }
    UserInboxes(where: {IsDeleted: {_eq: false}}) 
    {
      User 
      {
        UserId
        FirstName
        LastName
      }
    }
  }
}
`;


export const UPDATE_INBOX_DETAILS = gql`
mutation MyMutation ($inboxId: Int!, $inboxName: String, $planId: Int) {
  update_msgbox_Inbox(where: {InboxId: {_eq: $inboxId}}, _set: {Name: $inboxName, PlanId: $planId}) {
    returning {
      InboxId
    }
    affected_rows
  }
}
`;

export const UPDATE_API_ACCOUNT_DETAILS = gql`
mutation MyMutation($apiAccountId: Int!, $appName: String, $apiKey: String, $partnerId: String, $partnerToken: String, $phoneNumber: String, $name: String!) {
  update_msgbox_APIAccount(where: {APIAccountId: {_eq: $apiAccountId}}, _set: {AppName: $appName, Key: $apiKey, PartnerId: $partnerId, PartnerToken: $partnerToken, PhoneNumber: $phoneNumber, Name: $name}) {
    returning {
      APIAccountId
    }
    affected_rows
  }
}
`;


export const REMOVE_USER_INBOX = gql`
mutation MyMutation ($inboxId: Int!, $userId: Int!) {
  update_msgbox_UserInbox(where: {InboxId: {_eq: $inboxId}, _and: {UserId: {_eq: $userId}}}, _set: {IsDeleted: true}) {
    affected_rows
    returning {
      InboxId
    }
  }
}`;

export const INSERT_USER_INBOX = gql`
mutation MyMutation ($inboxId: Int!, $orgId: Int!, $userId: Int!) {
  insert_msgbox_UserInbox(objects: {InboxId: $inboxId, OrganisationId: $orgId, UserId: $userId}){
  affected_rows
  returning{
    InboxId
    }
  }
}
`;

export const CHECK_USER_INBOX = gql`
query MyQuery ($inboxId: Int!, $userId: Int!) {
  msgbox_UserInbox(where: {InboxId: {_eq: $inboxId}, _and: {UserId: {_eq: $userId}}}) {
    IsDeleted
  }
}
`;


export const DELETE_USER_INBOX = gql`
mutation MyMutation ($inboxId: Int!, $userId: Int!) {
  delete_msgbox_UserInbox(where: {InboxId: {_eq: $inboxId}, _and: {UserId: {_eq: $userId}}})
  {
  affected_rows
  returning{
    InboxId
    }
  }
}
`;